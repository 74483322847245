import styled from "styled-components"
import {
  SectionSubHeading,
  SectionHeading,
  Commonpara,
  Commonh6,
} from "../Common/common.style"
import { device } from "../Common/device"
import { Row } from "react-bootstrap"

export const AboutSection = styled.section`
  background: #f5f5f5;
  padding: 100px 0px;

  @media ${device.tablet} {
    padding: 80px 10px;
  }
`
export const CustomRow = styled(Row)`
  align-items: center;
`

export const AboutImg = styled.div`
  .aboutImage {
  }
  @media ${device.laptop} {
    .aboutImage {
      width: 60%;
      height: auto;
      margin: auto;
    }
  }
`

export const HeadingLayout = styled.div`
  margin-bottom: 30px;

  @media ${device.laptop} {
    margin-top: 30px;
  }
`

export const Heading = styled(SectionHeading)`
  margin-bottom: 20px;

  @media ${device.tablet} {
    margin-bottom: 20px;
  }
`

export const SubHeading = styled(SectionSubHeading)`
  font-size: 16px;
  color: #69b051;
  font-weight: bold;
`
export const Role = styled(Commonh6)`
  font-size: 15px;
  color: #545454;
`

export const AboutText = styled.div`
  color: #545454;
  margin-: 20px;
  font-size: 15px;
  line-height: 1.5;
`

export const SliderOuterWrapper = styled.div`
  margin: 0px -15px; // left right margin of ImageHolder

  .slick-slide {
    line-height: 0;
  }
`

export const AboutGalleryWrapper = styled.div`
  outline: 0;
`

export const ImageHolder = styled.div`
  margin: 0px 15px 20px;

  .aboutUsSliderImg {
    height: 130px;
  }
`

export const NextPrev = styled.div`
  text-align: right;
  line-height: 1;
`

export const Button = styled.button`
  border: none;
  background: none;
  outline: none;
  line-height: 1;

  :focus {
    outline: 0;
  }
`

export const PrevImg = styled.img`
  width: 40px;
  cursor: pointer;
  transition: all 0.4s;
  :hover {
    transform: scale(1.1);
    transition: all 0.4s;
  }
`

export const NextImg = styled.img`
  width: 40px;
  cursor: pointer;
  transition: all 0.4s;
  :hover {
    transform: scale(1.1);
    transition: all 0.4s;
  }
`
